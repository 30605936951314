/* istanbul ignore file */
/**
 * @var string COOKIEに保存するAPI_KEY,SECRETのキー名
 */
export const COOKIE_KEY_SESSION = 'fl-nextjs-session'

/**
 * @var string COOKIEに保存するJWTアクセストークンのキー名
 */
export const COOKIE_KEY_SESSION_JWT = 'fl-nextjs-session-token'

/**
 * @var string COOKIEに保存するリフレッシュトークンのキー名
 */
export const COOKIE_KEY_SESSION_REFRESH = 'fl-nextjs-session-refresh'

/**
 * @var string COOKIEに保存するiron-sessionのキー名
 */
export const COOKIE_KEY_IRON_SESSION = 'fl-nextjs-sessid'

/**
 * @var string COOKIEのPHPのセッションキー名
 */
export const COOKIE_KEY_PHP_SESSION = 'PHPSESSID'

/**
 * @var string COOKIEのURL配布型ユーザー判定のためのキー名
 */
export const COOKIE_KEY_LIMITED_URL = 'limitd_url_key'

/**
 * @var string COOKIEのABテスト判定のためのキー名
 */
export const COOKIE_ABTESTING = 'flier_abtesting'

/**
 * @var string /apiへのリクエストを旧フロントと新フロントで分けるためのカスタムヘッダー
 */
export const CUSTOM_HEADER_FLIER_APP_KEY = 'FLIER-APP'
export const CUSTOM_HEADER_FLIER_APP_VALUE = 'Nextjs'
export const CUSTOM_HEADER_FLIER_APP = {
  [CUSTOM_HEADER_FLIER_APP_KEY]: CUSTOM_HEADER_FLIER_APP_VALUE,
}

/**
 * @var Record 解約理由の一覧
 */
export const CANCEL_REASONS = {
  '01': '利用料金が高かった',
  '03': '読みたい要約がなかった',
  '08': 'アプリやサイトが使いづらかった',
  '09': '他サービスへの切替のため（flier法人版や他社類似サービス）',
  '10': 'サービス自体を使わなくなった',
  '99': 'その他（コメント欄に詳細をご記入ください）',
}

/**
 * docomo決済用のサイトID
 */
export const SP_GOLD_SITE_ID = '00073476804'
export const SP_SILVER_SITE_ID = '00073476802'

/**
 * シルバー会員の上限冊数
 */
export const SILVER_MAX_BOOK_COUNT = 5

/**
 * ユーザーアイコンのデフォルト画像PATH
 */
export const DEFAULT_USER_ICON_PATH = `${process.env.IMG_SERVER}/user/0000000000.jpg`

/**
 * 絵文字
 */
export const EMOJI_HEARTEYES = '😍'
export const EMOJI_JOY = '😂'
export const EMOJI_FIRE = '🔥'
export const EMOJI_CLAP = '👏'
export const EMOJI_CRY = '😢'
export const EMOJI_OPENMOUSE = '😮'

export const inquiryType = [
  { id: '1', label: 'アプリ・サービスの使い方' },
  { id: '2', label: '課金・契約について' },
  { id: '3', label: 'パスワードを忘れた・ログインできない' },
  { id: '4', label: 'メールマガジンについて' },
  { id: '5', label: '法人契約の導入に関するお問い合わせ' },
  { id: '6', label: '取材・広報に関するお問い合わせ' },
  { id: '7', label: '書籍の要約リクエスト' },
  { id: '8', label: 'アプリ・サービスへのご要望' },
  { id: '9', label: '解約・退会の方法について' },
  { id: '10', label: '不具合・エラーについて' },
  { id: '11', label: 'その他' },
]
