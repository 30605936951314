import { NextSeo } from 'next-seo'

interface Props {
  message: string
}
export const MaintenancePage: React.FC<Props> = ({ message }) => {
  return (
    <>
      <NextSeo noindex={true} />
      <header className="relative h-auto border">
        <div className="flex justify-center items-center h-[50px] sm:h-[72px]">
          <img
            src={`${process.env.IMG_SERVER}/common/logo.png`}
            alt=""
            width="100"
          />
        </div>
      </header>
      <div className="mt-10 text-center">
        <img
          className="m-auto pc"
          src={`${process.env.IMG_SERVER}/maintenance/maintenance.gif`}
          alt=""
          width="400"
        />
        <br />
        <img
          className="m-auto sp"
          src={`${process.env.IMG_SERVER}/maintenance/maintenance.gif`}
          alt=""
          width="300"
        />
        <div className="m-8 text-2xl font-bold">現在メンテナンス中です</div>
        <div className="m-3 leading-[170%]">
          ご迷惑をおかけします。現在メンテナンス中のためサービスをご利用いただけません。
          <br />
          メンテナンス終了までしばらくお待ちください。
          <br />
          {message}
        </div>
      </div>
    </>
  )
}
