import { useEffect, useState } from 'react'
import { CUSTOM_HEADER_FLIER_APP } from '@/utils/constant'

export const useMaintenance = () => {
  const [maintenance, setMaintenance] =
    useState<{ result: boolean; message: string }>(null)

  useEffect(() => {
    ;(async () => {
      const res = await fetch(process.env.BASE_PATH + '/api/maintenance', {
        headers: {
          ...CUSTOM_HEADER_FLIER_APP,
        },
      }).then((res) => res.json())
      setMaintenance({ ...res })
    })()
  }, [])

  return maintenance
}
