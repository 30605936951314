export default {
  defaultTitle: '本の要約サービス flier(フライヤー)',
  description:
    'flier（フライヤー）はビジネスパーソンや就活生必携の「要約」サービスです。スキマ時間を活用して、厳選した話題のビジネス書・教養書がわずか10分で読める「時短読書」を始めませんか。',
  openGraph: {
    site_name: '本の要約サービス flier(フライヤー)',
    title: '本の要約サービス flier(フライヤー)',
    type: 'website',
    description:
      'flier（フライヤー）はビジネスパーソンや就活生必携の「要約」サービスです。スキマ時間を活用して、厳選した話題のビジネス書・教養書がわずか10分で読める「時短読書」を始めませんか。',
    images: [
      {
        url: process.env.IMG_SERVER + '/common/logo_share.jpg',
        width: 1200,
        height: 630,
        alt: '',
      },
    ],
  },
  facebook: {
    appId: '731695613572119',
  },
  twitter: {
    cardType: 'summary_large_image',
  },
}
