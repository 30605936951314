import dynamic from 'next/dynamic'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { DefaultSeo } from 'next-seo'
import { AppProps } from 'next/app'
import SEO from '../../next-seo.config'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/styles/global.scss'
import { MaintenancePage } from '@/features/maintenance/MaintenancePage'
import { useMaintenance } from '@/hooks/useMaintenance'

const AppProvider = dynamic(
  () => import('../providers/AppProvider').then((mod) => mod.AppProvider),
  { ssr: true }
)
const Layout = dynamic(
  () =>
    import('../features/global/components/Layout/Layout').then(
      (mod) => mod.Layout
    ),
  { ssr: true }
)

export default function App(props: AppProps): JSX.Element {
  const { Component, pageProps } = props
  const maintenance = useMaintenance()

  return (
    <AppProvider>
      <DefaultSeo {...SEO} />
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <meta name="copyrights" content="(C) Flier Inc." />
        <meta name="apple-itunes-app" content="app-id=1022261165" />
        <meta name="google-play-app" content="app-id=com.flierinc.flier" />
        <link
          rel="apple-touch-icon"
          href={`${process.env.IMG_SERVER}/common/logo_180.png`}
        />
        <link
          rel="icon"
          type="image/x-icon"
          href={`${process.env.IMG_SERVER}/common/logo_mini.png`}
        />
      </Head>
      {maintenance?.result ? (
        <MaintenancePage message={maintenance.message} />
      ) : (
        <Layout>
          <Component {...pageProps} />
        </Layout>
      )}
    </AppProvider>
  )
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
}
